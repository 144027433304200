import React from "react"
import Layout from "@src/components/Layout"

import Button from "@src/components/Button"
import VideoHeader from "./sections/VideoHeader/VideoHeader"
import ClientStrip from "../Home/sections/ClientStrip/ClientStrip"
import WorkflowAutomation from "./sections/WorkflowAutomation/WorkflowAutomation"
import StreamlineOperations from "./sections/StreamlineOperations/StreamlineOperations"
import Testimonials from "./sections/Testimonials/Testimonials"
import FAQ from "./sections/FAQ/FAQ"
import HowItWorks from "../Home/sections/HowItWorks/HowItWorks"
import NoVendorLockIn from "../Home.alternate/sections/NoVendorLockIn/NoVendorLockIn"

import * as styles from "./AutomationConciergeServices.module.scss"

const IndexPage = () => {
  return (
    <Layout
      title="We build, you grow"
      description="Automate your internal workflows and save tens of $1000’s on engineering resources."
      url="https://canonic.dev/video-sales-letter"
      // ogImage={OG}
    >
      <section className={styles.vsl} style={{ position: "relative" }}>
        <VideoHeader />
      </section>
      <ClientStrip />
      <WorkflowAutomation />
      <StreamlineOperations />
      <Testimonials />
      <section className={styles.vslHowItWorks}>
        <HowItWorks title="We are a product first company" />
        <a
          href="https://calendly.com/d/2jk-7gz-j7g/30min"
          target="_blank"
          className={styles.vslSmallerCta}
        >
          <Button isLean isPrimary>
            Book a call now ✅
          </Button>
        </a>
      </section>
      <section className={styles.vslVendorLock}>
        <NoVendorLockIn
          subtitle={"Connect your data, add your logic and snap together UI"}
        />
        <a
          href="https://calendly.com/d/2jk-7gz-j7g/30min"
          target="_blank"
          className={styles.vslVendorLockCta}
        >
          <Button isLean isPrimary>
            Book a call now ✅
          </Button>
        </a>
      </section>
      <FAQ />
    </Layout>
  )
}
export default IndexPage
