import React from "react"

import Row from "../../../components/Row/Row"
import Button from "@src/components/Button"
import StepOne from "../../../../../icons/v2/automation-step-1.svg"
import StepTwo from "../../../../../icons/v2/automation-step-2.svg"
import StepThree from "../../../../../icons/v2/automation-step-3.svg"

import * as styles from "./WorkflowAutomation.module.scss"

const WorkflowAutomation = () => {
  return (
    <section>
      <Row className={styles.topMargin}>
        <div className={styles.automation}>
          <h2 className={styles.automationHeading}>
            Automate internal workflows, effortlessly.
          </h2>

          <div className={styles.automationSubtitle}>
            <p>Build business apps without the tech headache. </p>
            <p>
              Leave the tech to us and grow your business without distractions
            </p>
          </div>

          <div className={styles.automationSteps}>
            <div className={styles.automationStepsStep}>
              <figure>
                <img src={StepOne} />
              </figure>
              <h3>Step 1</h3>
              <p>Help us understand your problems and pain points.</p>
              <p className={styles.automationStepsStepTimeFrame}>⏱ 1 Day</p>
            </div>

            <div className={styles.automationStepsStep}>
              <figure>
                <img src={StepTwo} />
              </figure>
              <h3>Step 2</h3>
              <p>
                We design the solution to automate boring, repetitive tasks.
              </p>
              <p className={styles.automationStepsStepTimeFrame}>⏱ 1 Week</p>
            </div>
            <div className={styles.automationStepsStep}>
              <figure>
                <img src={StepThree} />
              </figure>
              <h3>Step 3</h3>
              <p>We build your internal workflows and maintain them for you.</p>
              <p className={styles.automationStepsStepTimeFrame}>⏱ 1 Month</p>
            </div>
          </div>
        </div>
        <a
          href="https://calendly.com/d/2jk-7gz-j7g/30min"
          target="_blank"
          className={styles.automationCta}
        >
          <Button isLean isPrimary>
            Book a call now ✅
          </Button>
        </a>
      </Row>
    </section>
  )
}

export default WorkflowAutomation
