// extracted by mini-css-extract-plugin
export var cards = "Testimonials-module--cards--1N-l_";
export var cardsComment = "Testimonials-module--cardsComment--1Cf3c";
export var cardsIdentityRow = "Testimonials-module--cardsIdentityRow--2_qEo";
export var cardsIdentityRowDetails = "Testimonials-module--cardsIdentityRowDetails--IGkUq";
export var cardsIdentityRowDetailsDP = "Testimonials-module--cardsIdentityRowDetailsDP--3dPBI";
export var cardsRatings = "Testimonials-module--cardsRatings--1QDpl";
export var cardsWrapper = "Testimonials-module--cardsWrapper--3HQzo";
export var halfStar = "Testimonials-module--halfStar--101Vr";
export var testimonials = "Testimonials-module--testimonials--3PlD5";
export var testimonialsContent = "Testimonials-module--testimonialsContent--2jYE_";
export var testimonialsCta = "Testimonials-module--testimonialsCta--15hDP";
export var vsl = "Testimonials-module--vsl--3U0Hm";
export var vslHeader = "Testimonials-module--vslHeader--2dvX8";
export var vslHeaderCall = "Testimonials-module--vslHeaderCall--2PBAW";
export var vslSmallerCta = "Testimonials-module--vslSmallerCta--1I2ff";
export var vslVendorLock = "Testimonials-module--vslVendorLock--1lZSC";
export var vslVendorLockCta = "Testimonials-module--vslVendorLockCta--2XvDi";