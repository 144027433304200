// extracted by mini-css-extract-plugin
export var automation = "WorkflowAutomation-module--automation--Ychf9";
export var automationCta = "WorkflowAutomation-module--automationCta--1gqwB";
export var automationHeading = "WorkflowAutomation-module--automationHeading--qpWh7";
export var automationSteps = "WorkflowAutomation-module--automationSteps--tBdL4";
export var automationStepsStep = "WorkflowAutomation-module--automationStepsStep--12ndF";
export var automationStepsStepTimeFrame = "WorkflowAutomation-module--automationStepsStepTimeFrame--9BuB5";
export var automationSubtitle = "WorkflowAutomation-module--automationSubtitle--2zykD";
export var topMargin = "WorkflowAutomation-module--topMargin--2TJiw";
export var vsl = "WorkflowAutomation-module--vsl--XSN4-";
export var vslHeader = "WorkflowAutomation-module--vslHeader--s2eUD";
export var vslHeaderCall = "WorkflowAutomation-module--vslHeaderCall--1jQ-h";
export var vslSmallerCta = "WorkflowAutomation-module--vslSmallerCta--2FUvr";
export var vslVendorLock = "WorkflowAutomation-module--vslVendorLock--pExq-";
export var vslVendorLockCta = "WorkflowAutomation-module--vslVendorLockCta--cQ2i8";