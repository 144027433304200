import React from "react"
import Row from "../../../components/Row/Row"
import FAQ from "@src/layouts/Pricing/components/PricingFAQs/FAQ"
import * as styles from "./FAQ.module.scss"
const FAQs = () => {
  return (
    <section>
      <Row className={styles.topMargin}>
        <div className={styles.faq}>
          <h2>FAQs</h2>
          {faqs.map((qa, i) => (
            <FAQ faq={qa} index={i} />
          ))}
        </div>
      </Row>
    </section>
  )
}

const faqs = [
  {
    question: "How long does it take to complete a project?",
    answer:
      "It entirely depends on the complexity of the project, but it takes approximately 1 day to set up and conduct the onboarding interview, 1 week to understand the business and design the solution, and 1 month or less to build out and integrate into your team's workflow",
  },
  {
    question:
      "Does Canonic offer ongoing maintenance and support for their software product?",
    answer:
      "You can always reach out to us at support@canonic.dev or on Discord. For any changes in the tool we charge $30/hour",
  },
  {
    question:
      " What is the process of collaborating with Canonic on a project?",
    answer: "Contact us at sales@canonic.dev",
  },
  {
    question: "What is the payment process for working with Canonic?",
    answer:
      "We support all major credit cards globally via our payment provider.",
  },
  {
    question:
      "What is the process for requesting changes or revisions to a project once it has been completed?",
    answer:
      "You can connect with your account manager, brief them on the changes required and they will share the costs accordingly.",
  },
  {
    question: "Can Canonic work with our existing technology stack?",
    answer:
      "Yes, we can add any third-party service as an integration and make their API's available within Canonic as long they have the right documentation.",
  },
]
export default FAQs
