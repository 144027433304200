// extracted by mini-css-extract-plugin
export var topMargin = "VideoHeader-module--topMargin--288z0";
export var videoHeader = "VideoHeader-module--videoHeader--1cc-_";
export var videoHeaderCTA = "VideoHeader-module--videoHeaderCTA--cBXbK";
export var videoHeaderHeading = "VideoHeader-module--videoHeaderHeading--QpVJX";
export var videoHeaderHeadingBlueText = "VideoHeader-module--videoHeaderHeadingBlueText--3JUr7";
export var videoHeaderPHAccolade = "VideoHeader-module--videoHeaderPHAccolade--araXR";
export var videoHeaderSubtitle = "VideoHeader-module--videoHeaderSubtitle--XHJ8l";
export var videoHeaderSubtitleHightlight = "VideoHeader-module--videoHeaderSubtitleHightlight--_5-8F";
export var videoHeaderVideo = "VideoHeader-module--videoHeaderVideo--1oqpj";
export var vsl = "VideoHeader-module--vsl--1e9I3";
export var vslHeader = "VideoHeader-module--vslHeader--3f1WQ";
export var vslHeaderCall = "VideoHeader-module--vslHeaderCall--2Hgjb";
export var vslSmallerCta = "VideoHeader-module--vslSmallerCta--30-8e";
export var vslVendorLock = "VideoHeader-module--vslVendorLock--2YHXm";
export var vslVendorLockCta = "VideoHeader-module--vslVendorLockCta--2ULKg";