import React from "react"
import Row from "../../../components/Row/Row"
import PH1 from "../../../../../icons/v2/ph-1.png"
import PH2 from "../../../../../icons/v2/ph-2.png"
import Button from "@src/components/Button"
import Video from "@src/videos/vsl.mp4"

import * as styles from "./VideoHeader.module.scss"
const VideoHeader = () => {
  return (
    <Row className={styles.topMargin}>
      <div className={styles.videoHeader}>
        <div className={styles.videoHeaderPHAccolade}>
          <a
            href="https://www.producthunt.com/posts/canonic?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-canonic"
            target="_blank"
          >
            <img
              src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=270736&theme=neutral&period=daily"
              alt="Canonic - A&#0032;low&#0032;code&#0032;platform&#0032;to&#0032;craft&#0032;APIs&#0032;in&#0032;minutes&#0046; | Product Hunt"
              style={{ width: "250px", height: "54px" }}
            />
          </a>
          <a
            href="https://www.producthunt.com/posts/canonic-2?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-canonic&#0045;2"
            target="_blank"
          >
            <img
              src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=309006&theme=neutral&period=daily"
              alt="Canonic - A&#0032;low&#0032;code&#0032;platform&#0032;to&#0032;craft&#0032;APIs&#0032;in&#0032;minutes&#0046; | Product Hunt"
              style={{ width: "242px", height: "108px" }}
            />
          </a>
        </div>
        <div className={styles.videoHeaderHeading}>
          <h1>
            We Build,{" "}
            <span className={styles.videoHeaderHeadingBlueText}>You Grow</span>
          </h1>
        </div>
        <div className={styles.videoHeaderSubtitle}>
          <p>Automate your internal workflows and</p>
          <p className={styles.videoHeaderSubtitleHightlight}>
            save tens of $1000’s on engineering resources.
          </p>
        </div>
        <div className={styles.videoHeaderVideo}>
          <video autoPlay={true} playsInline controls loop src={Video} />
        </div>
        <div className={styles.videoHeaderCTA}>
          <a
            href="https://calendly.com/d/2jk-7gz-j7g/30min"
            target="_blank"
            className={styles.vslHeaderCall}
          >
            <Button isLean isPrimary>
              Book a call now ✅
            </Button>
          </a>
        </div>
      </div>
    </Row>
  )
}

export default VideoHeader
