import React from "react"
import Row from "../../../components/Row/Row"
import Button from "@src/components/Button"
import Column from "../../../components/Column/Column"
import FlexedBicepEmoji from "../../../../../icons/v2/flexed-bicep-emoji.svg"
import HourglassEmoji from "../../../../../icons/v2/hourglass-emoji.svg"
import MoneyBagEmoji from "../../../../../icons/v2/money-bag-emoji.svg"

import * as styles from "./StreamlineOperations.module.scss"

const StreamlineOperations = () => {
  return (
    <section>
      <Row className={styles.operations}>
        <div className={styles.operationsContent}>
          <h3>Discovery call designed to streamline your operations</h3>
          <p>
            We have automated internal workflows for countless industries and
            specialise in e-commerce businesses. At the very least, you’ll take
            away actionable steps to scale operations predictably
          </p>
          <a
            href="https://calendly.com/d/2jk-7gz-j7g/30min"
            target="_blank"
            className={styles.operationsCta}
          >
            <Button isPrimary isLean className={styles.operationsCta}>
              Book a call now ✅
            </Button>
          </a>
        </div>
        <div className={styles.operationsCards}>
          <Column.Wrap numPerRow={3}>
            <Column
              title={"Reduce manual effort by 10X"}
              icon={FlexedBicepEmoji}
              description={
                "Using automated internal tools, significantly reduce manual effort"
              }
            />
            <Column
              title={"Save countless number of hours"}
              icon={HourglassEmoji}
              description={
                "Doing everything from scratch? Ain’t nobody got time for that."
              }
            />
            <Column
              title={"Save a LOT of money"}
              icon={MoneyBagEmoji}
              description={
                "Save money spent on hiring and managing engineers and developers"
              }
            />
          </Column.Wrap>
        </div>
      </Row>
    </section>
  )
}

export default StreamlineOperations
