import React from "react"
import Row from "../../../components/Row/Row"
import Button from "@src/components/Button"
import Chaitanya from "../../../../../icons/v2/PH-testimonials/chitanya-agarwal.png"
import Sankalp from "../../../../../icons/v2/PH-testimonials/sankalp-a.png"
import Doc from "../../../../../icons/v2/PH-testimonials/doc-williams.png"
import Tripti from "../../../../../icons/v2/PH-testimonials/tripti-bhandari.png"
import Sapna from "../../../../../icons/v2/PH-testimonials/sapna-agarwal.png"
import Vibhor from "../../../../../icons/v2/PH-testimonials/vibhor-singh.png"
import PHLogo from "../../../../../icons/v2/PH-testimonials/ph-logo.png"
import FullStar from "../../../../../icons/v2/PH-testimonials/full-star.svg"
import HalfStar from "../../../../../icons/v2/PH-testimonials/half-star.svg"

import * as styles from "./Testimonials.module.scss"
const Testimonials = () => {
  return (
    <section>
      <Row className={styles.testimonials}>
        <div className={styles.testimonialsContent}>
          <h2>Don’t just take our word for it</h2>
          <p>Hear what business owners are saying about us</p>
        </div>
        <Cards />
        <a
          href="https://calendly.com/d/2jk-7gz-j7g/30min"
          target="_blank"
          className={styles.testimonialsCta}
        >
          <Button isLean isPrimary>
            Book a call now ✅
          </Button>
        </a>
      </Row>
    </section>
  )
}

const Cards = () => {
  return (
    <div className={styles.cardsWrapper}>
      {data.map(t => {
        return (
          <div className={styles.cards}>
            <a
              href="https://www.producthunt.com/products/canonic/reviews"
              target="_blank"
              rel="noreferrer"
            >
              <div className={styles.cardsIdentityRow}>
                <div className={styles.cardsIdentityRowDetails}>
                  <img
                    src={t.dp}
                    className={styles.cardsIdentityRowDetailsDP}
                  />
                  <div>
                    <h4>{t.name}</h4>
                    <p>{t.qualification}</p>
                  </div>
                </div>
                <img src={PHLogo} />
              </div>
              <div className={styles.cardsRatings}>
                {t.ratings === 5 ? <FiveStarRating /> : <FourAndHalf />}
              </div>
              <div className={styles.cardsComment}>
                <p>{t.comment}</p>
              </div>
            </a>
          </div>
        )
      })}
    </div>
  )
}
export default Testimonials

const FiveStarRating = () => (
  <div>
    <img src={FullStar} />
    <img src={FullStar} />
    <img src={FullStar} />
    <img src={FullStar} />
    <img src={FullStar} />
  </div>
)
const FourAndHalf = () => (
  <div>
    <img src={FullStar} />
    <img src={FullStar} />
    <img src={FullStar} />
    <img src={FullStar} />
    <img src={HalfStar} className={styles.halfStar} />
  </div>
)

const data = [
  {
    name: "Chaitanya Agrawal",
    qualification: "CA by qualification. IB Analyst",
    dp: Chaitanya,
    comment:
      "“Unsurprisingly a boon for non tech folks! Easy to understand and very intuitive!”",
    ratings: 5,
  },
  {
    name: "Sankalp A.",
    qualification: "UX Designer",
    dp: Sankalp,
    comment:
      "“The usability of the website/software is straight forward once integrated with your system/software, it provides a good value of data and diagrams creation in a very detailed format, and specificity is also there.It quickly helps me and my company to manage tasks and set up targets and deadlines for upcomming projects; along with it, it solves no coding/less coding issues in my non-technological ferm.”",
    ratings: 4,
  },
  {
    name: "Doc Williams",
    qualification: "Building Operations to Scale Brands",
    dp: Doc,
    comment:
      "“I'm loving all the new changes and the potential of this platform! Very excited to start using all the new features!”",
    ratings: 5,
  },
  {
    name: "Tripti Bhandari",
    qualification: "Student",
    dp: Tripti,
    comment:
      "“Just came across this product! Kudos to the team for creating such an outstanding platform that will allow individuals to create products without worrying about money and time.”",
    ratings: 5,
  },
  {
    name: "Sapna Agrawal",
    qualification: "CA by qualification. IB Analyst",
    dp: Sapna,
    comment:
      "“Thanks Canonic team for building this amazing platform that enables so many people to build their product without spending much time, code effort and money!”",
    ratings: 5,
  },
  {
    name: "Vibhor Kumar Singh",
    qualification: "",
    dp: Vibhor,
    comment:
      "I'd like to thank the team at Canonic for building such a great platform for us entrepreneurs who have little to no idea about coding. My venture has inhouse full stack developers and 70% of their work is done using Canonic. The agility and ease of use definitely helped me learn it myself as well. Kudos to the team.",
    ratings: 5,
  },
]
