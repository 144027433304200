// extracted by mini-css-extract-plugin
export var Triangle = "PricingFAQs-module--Triangle--2xX0x";
export var faqRow = "PricingFAQs-module--faqRow--vsP_Y";
export var faqRowTitle = "PricingFAQs-module--faqRowTitle--1UPcJ";
export var faqs = "PricingFAQs-module--faqs--2nsqh";
export var faqsFaq = "PricingFAQs-module--faqsFaq--NirEX";
export var faqsFaqAnswer = "PricingFAQs-module--faqsFaqAnswer--3aZ3L";
export var faqsFaqQuestion = "PricingFAQs-module--faqsFaqQuestion--29-GI";
export var faqsOpenAnswer = "PricingFAQs-module--faqsOpenAnswer--3rl6T";
export var faqsOpenQuestion = "PricingFAQs-module--faqsOpenQuestion--qhoht";
export var open = "PricingFAQs-module--open--RpX28";
export var pricingFaqs = "PricingFAQs-module--pricingFaqs--OrIzL";
export var section = "PricingFAQs-module--section--3dkUO";